import { useMediaQuery } from 'react-responsive';
import { useMemo } from 'react';

export const useScreenWidth = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1056px)',
  });
  const isLargeDesktop = useMediaQuery({
    query: '(min-width: 1312px)',
  });

  return useMemo(
    () => ({
      isDesktop,
      isLargeDesktop,
    }),
    [isDesktop, isLargeDesktop],
  );
};
